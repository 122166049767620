import React from "react";
import {graphql} from "gatsby";
import Layout from "../components/Layout"
import styled from "styled-components"
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import { pc, sp, tab } from '../styles/media';
import useMedia from 'use-media';
import PropertyConverter from "../components/PropertyConverter";
import ContractedMark from "../components/ContractedMark";
import { Helmet } from "react-helmet"


const ContainerDiv = styled.div`
  width: 100%;
  max-width: 100%;
  font-size: 18px;
`

const GatsbyImagePcStyle = {
  "height": "100%",
  "object-fit": "contain",
}

const DetailContainer = styled.div`
  margin-top: 30px;
`
const SummarySection = styled.div`
  ${sp`
  & h1 {
    font-size: 22px;
    font-weight: bold;
    border-bottom: solid 1px #cbd0d5;
    margin-bottom: 10px;
  }
  & p {
    padding: 8px;
    font-size: 18px;
  }
  `}
  ${pc`
  & h1 {
    font-size: 24px;
    font-weight: bold;
    border-bottom: solid 1px #cbd0d5;
    margin-bottom: 10px;
  }
  & p {
    padding: 8px;
    font-size: 18px;
  }
  `}
  margin-bottom: 30px;
`
const DetailSection = styled.div`
  ${sp`
  & h2 {
    // padding: 8px 0px;
    // margin: 0 12px;
    // font-weight: 600;
    font-size: 22px;
    // text-align: center;
    // background: linear-gradient(to bottom, #f3f5f7, #f8fbf9);
    // height: 36px;
    // border-bottom: solid 1px #cbd0d5;    
    border-bottom: solid 1px #cbd0d5;    
    font-weight: bold;
    margin-bottom: 10px;
  }
  & li {
    height: 36.5px;
    letter-spacing: normal;
    line-height: 20.41px;  
  }
  `}
  ${pc`
  & h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    border-bottom: solid 1px #cbd0d5;    
  }
  & li {
    padding: 5px 0px;
    font-size: 18px;
  }
  `}
  margin-bottom: 30px;
`
const DrawingSection = styled.div`
  margin-bottom: 30px;
  & h2 {
    ${sp`
    font-size: 22px;
    `}
    ${pc`
    font-size: 24px;
    `}
    border-bottom: solid 1px #cbd0d5;    
    font-weight: bold;
    margin-bottom: 10px;
  }
`
const DrawingCell = styled.div`
  width: 100%;
  padding: 15px;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
`
const DrawingItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  ${sp`
  width: 100%;
  `}
  ${pc`
  width: 46%;
  `}
`
const DrawingImg = styled.div`
  height: 300px;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 10px;
  background-color: #fff;
`
const RemarksSection = styled.div`
  margin-bottom: 30px;
  & h2 {
    ${sp`
      font-size: 22px;    
    `}
    ${pc`
      font-size: 24px;
    `}
    font-weight: bold;
    margin-bottom: 10px;
  }
  & p {
    font-size: 18px;
    padding: 3px 5px;
  }
`
const DetailInformationCell = styled.ul`
  ${sp`
  display: table;
  padding: 0 16px;
  `}
  ${pc`
  display: table;
  `}
  font-size: 18px;
`
const DetailInformationItem = styled.li`
  ${sp`
  display: table-row;
  `}
  ${pc`
  display: table-row;
  `}
`
const DetailInformationItemHead = styled.span`
  ${sp`
  display: table-cell;
  width: 96px;
  padding: 8px 12px;
  font-weight: 600;
  border-bottom: 1px solid #e0e0e0;
  `}
  ${pc`
  display: table-cell;
  font-weight: bold;
  text-align: center;
  padding: 7px 5px;
  width: 100px;
  `}
`
const DetailInformationItemSeparate = styled.span`
  ${sp`
  display: table-cell;
  font-weight: 400;
  border-bottom: 1px solid #e0e0e0;
  `}
  ${pc`
  display: table-cell;
  text-align: center;
  `}
`
const DetailInformationItemData = styled.span`
  ${sp`
  display: table-cell;
  font-weight: 400;
  padding: 8px 12px;
  border-bottom: 1px solid #e0e0e0;
  & li {
    list-style: disc;
    margin-left: 0.5em;
    height: auto;
    line-height: 1.3em;
  }
  `}
  ${pc`
  display: table-cell;
  & li {
    list-style: disc;
    margin-left: 0.5em;
  }
  `}
`


// TODO:: 成約済みスタンプ
export default function PropertyDetail({ data }) {
  const isPc = useMedia({minWidth: '560px'});
  const propertyObj = data.Data.getProperty;
  const convertedObj = new PropertyConverter(propertyObj).convert();
  const width_ = isPc ? "300px" : "100%";
  const height_ = isPc ? "150px" : "300px";
  const fontSize_ = isPc ? "32px" : "20px";

  // no-image-white.jpg が選択されている時は表示させないための調整
  let gridTemplateRowsSp = "300px 150px 150px;";
  if(convertedObj.image04 === null
    && convertedObj.image05 === null){
      if(convertedObj.image02 === null
        && convertedObj.image03 === null){
          gridTemplateRowsSp = "300px;";
        }else{
          gridTemplateRowsSp = "300px 150px;";
        }
    }
  // const noneFlag01 = convertedObj.image01 === null ? "none" : "block";
  const noneFlag02 = convertedObj.image02 === null ? "none" : "block";
  const noneFlag03 = convertedObj.image03 === null ? "none" : "block";
  const noneFlag04 = convertedObj.image04 === null ? "none" : "block";
  const noneFlag05 = convertedObj.image05 === null ? "none" : "block";
  const ImageSection = styled.div`
  width: 100%;
  padding: 15px;
  display: grid;
  ${sp`
  grid-template-rows: ${gridTemplateRowsSp}
  grid-template-columns: calc(100% / 2) calc(100% / 2);
  `}
  ${pc`
  height: 300px;
  grid-template-rows: 150px 150px;
  grid-template-columns: calc((100% - 10px*2) / 7 * 3) calc((100% - 10px*2) / 7 * 2) calc((100% - 10px*2) / 7 * 2);
  `}
`
const ImageCell = styled.div`
  position: relative;
  height: auto;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`

    return (
      <Layout>
        <Helmet>
          <title>【ビオエスト株式会社】｜関西エリアの倉庫・工場・土地物件</title>
          <meta name="description" content="倉庫・工場・土地等の運用についてお悩みなら、【ビオエスト株式会社】にご相談ください。
主に大阪・和歌山・奈良・兵庫エリアにて、賃貸・売買・管理・物件調査、査定を通じて適切な運用方法をご一緒に検討いたします。" />
        </Helmet>

        <ContainerDiv>
        <SummarySection>
              <h1>{convertedObj.title}</h1>
              <p>{convertedObj.prefecture}{convertedObj.city}{convertedObj.address}</p>
            </SummarySection>
            <RemarksSection>
              <div>{convertedObj?.remarks?.split("\n").map((line) => {
                return (
                  <p>{line}</p>
                )
              })}</div>
            </RemarksSection>
          <ImageSection>
            <ImageCell style={isPc ? {"grid-row": "1 / 3", "grid-column": "1 / 2"} : {"grid-row": "1 / 2", "grid-column": "1 / 3"}}>
              {convertedObj.iscontracted ? <ContractedMark width={width_} height={height_} fontSize={fontSize_} /> : null}
              <GatsbyImage style={GatsbyImagePcStyle} image={getImage(convertedObj.pathPropertyImages[0])} />
            </ImageCell>
            <ImageCell style={isPc ? {"grid-row": "1 / 2", "grid-column": "2 / 3"}: {"grid-row": "2 / 3", "grid-column": "1 / 2", "display": noneFlag02}}>
              <GatsbyImage style={GatsbyImagePcStyle} image={getImage(convertedObj.pathPropertyImages[1])} />
            </ImageCell>
            <ImageCell style={isPc ? {"grid-row": "1 / 2", "grid-column": "3 / 4"} : {"grid-row": "2 / 3", "grid-column": "2 / 3", "display": noneFlag03}}>
              <GatsbyImage style={GatsbyImagePcStyle} image={getImage(convertedObj.pathPropertyImages[2])} />
            </ImageCell>
            <ImageCell style={isPc ? {"grid-row": "2 / 3", "grid-column": "2 / 3"} : {"grid-row": "3 / 4", "grid-column": "1 / 2", "display": noneFlag04}}>
              <GatsbyImage style={GatsbyImagePcStyle} image={getImage(convertedObj.pathPropertyImages[3])} />
            </ImageCell>
            <ImageCell style={isPc ? {"grid-row": "2 / 3", "grid-column": "3 / 4"} : {"grid-row": "3 / 4", "grid-column": "2 / 3", "display": noneFlag05}}>
              <GatsbyImage style={GatsbyImagePcStyle} image={getImage(convertedObj.pathPropertyImages[4])} />
            </ImageCell>
          </ImageSection>
          <DetailContainer>
            <DetailSection>
              <h2>物件情報</h2>
              <DetailInformationCell>
                <DetailInformationItem>
                  <DetailInformationItemHead>所在地</DetailInformationItemHead>
                  <DetailInformationItemSeparate>：</DetailInformationItemSeparate>
                  <DetailInformationItemData>{convertedObj.prefecture}{convertedObj.city}{convertedObj.address}</DetailInformationItemData>
                </DetailInformationItem>
                <DetailInformationItem>
                  <DetailInformationItemHead>面積</DetailInformationItemHead>
                  <DetailInformationItemSeparate>：</DetailInformationItemSeparate>
                  {/* 合計: 1000㎡/302.50坪（1F: 500㎡/150.00坪, 2F: 300㎡/90.00坪, 3F: 200㎡/60.00坪） */}
                  <DetailInformationItemData>合計：{convertedObj.buildingAreaTotal}㎡/{convertedObj.buildingAreaTotalTsubo}坪　{
                    (convertedObj?.buildingAreaEach?.length > 0) ? "(" : ""
                  }{
                    convertedObj?.buildingAreaEach?.map((area, i) => {
                      let eol_ = convertedObj?.buildingAreaEach?.length===i+1 ? "" : ", "
                      return (
                        // i+1 + "F：" + area + "㎡/" + convertedObj.buildingAreaEachTsubo[i] + "坪" + eol_ 
                        i+1 + "F：" + area + eol_ 
                      )
                    })
                  }{
                    (convertedObj.buildingAreaEach.length > 0) ? "）" : ""
                  }</DetailInformationItemData>
                </DetailInformationItem>
                <DetailInformationItem>
                  <DetailInformationItemHead>価格</DetailInformationItemHead>
                  <DetailInformationItemSeparate>：</DetailInformationItemSeparate>
                  <DetailInformationItemData>{convertedObj.rent} 円</DetailInformationItemData>
                </DetailInformationItem>
                <DetailInformationItem>
                  <DetailInformationItemHead>備考</DetailInformationItemHead>
                  <DetailInformationItemSeparate></DetailInformationItemSeparate>
                <DetailInformationItemData>
                  <ul>
                    {convertedObj?.keywords?.map((word) => {
                      return (
                      <li>{word}</li>
                      )
                    })}
                  </ul>
                  </DetailInformationItemData></DetailInformationItem>
              </DetailInformationCell>
            </DetailSection>
            <DrawingSection>
              <h2>{convertedObj.drawing01===null ? "" : "図面"}</h2>
              <DrawingCell>
                {
                  convertedObj.drawing01===null ? "" : <DrawingItem>
                  <DrawingImg>
                    <GatsbyImage objectFit="contain" style={GatsbyImagePcStyle} image={getImage(convertedObj.pathDrawingImages[0])} />
                  </DrawingImg>
                  <p>{convertedObj.drawingTitle01}</p>
                  </DrawingItem>
                }
                {
                  convertedObj.drawing02===null ? "" : <DrawingItem>
                  <DrawingImg>
                    <GatsbyImage objectFit="contain" style={GatsbyImagePcStyle} image={getImage(convertedObj.pathDrawingImages[1])} />
                  </DrawingImg>
                  <p>{convertedObj.drawingTitle02}</p>
                  </DrawingItem>
                }
                {
                  convertedObj.drawing03===null ? "" : <DrawingItem>
                  <DrawingImg>
                    <GatsbyImage objectFit="contain" style={GatsbyImagePcStyle} image={getImage(convertedObj.pathDrawingImages[2])} />
                  </DrawingImg>
                  <p>{convertedObj.drawingTitle03}</p>
                  </DrawingItem>
                }
                {
                  convertedObj.drawing04===null ? "" : <DrawingItem>
                  <DrawingImg>
                    <GatsbyImage objectFit="contain" style={GatsbyImagePcStyle} image={getImage(convertedObj.pathDrawingImages[3])} />
                  </DrawingImg>
                  <p>{convertedObj.drawingTitle04}</p>
                  </DrawingItem>
                }
                {
                  convertedObj.drawing05===null ? "" : <DrawingItem>
                  <DrawingImg>
                    <GatsbyImage objectFit="contain" style={GatsbyImagePcStyle} image={getImage(convertedObj.pathDrawingImages[4])} />
                  </DrawingImg>
                  <p>{convertedObj.drawingTitle05}</p>
                  </DrawingItem>
                }
              </DrawingCell>
            </DrawingSection>
          </DetailContainer>
        </ContainerDiv>
      </Layout>
    )
}

export const query = graphql`
query PropertDetailQuery($id: ID!){
    Data {
      getProperty(id: $id) {
        prefecture
        city
        address
        buildingAreaTotal
        buildingAreaEach
        createdAt
        id
        iscontracted
        keywords
        remarks
        rent
        title
        updatedAt
        image01
        image02
        image03
        image04
        image05
        drawing01
        drawing02
        drawing03
        drawing04
        drawing05
        drawingTitle01
        drawingTitle02
        drawingTitle03
        drawingTitle04
        drawingTitle05
        pathPropertyImages{
          childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG)
          }
        }
        pathDrawingImages{
          childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG)
          }
        }
      }
    }
  }
`
